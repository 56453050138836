import {LayoutProps, useAuthenticationContext} from 'jaen'
import {ChakraProvider} from '@chakra-ui/react'
import {Box, Heading} from '@chakra-ui/react'
import AppLayout from '../../shared/containers/AppLayout'
import theme from '../theme/theme'

const Layout: React.FC<LayoutProps> = ({children, pageProps}) => {
  const path = pageProps.location.pathname
  const hiddenTopNavPaths = ['/profile', '/blog-post']

  const {isAuthenticated} = useAuthenticationContext()

  return (
    <ChakraProvider theme={theme}>
      <AppLayout
        isDocs={path.startsWith('/docs')}
        path={path}
        topNavProps={{
          isVisible:
            !hiddenTopNavPaths.some(hiddenPath =>
              path.startsWith(hiddenPath)
            ) && !isAuthenticated
        }}>
        {children}
      </AppLayout>
    </ChakraProvider>
  )
}

export default Layout
