import Text from './TextField'
import Image from './ImageField'
import Index from './IndexField'
import Section from './SectionField'
// import Mdx from './MdxField'

export const Field = {
  Text,
  Image,
  Index,
  Section
  // Mdx
}
