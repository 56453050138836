module.exports = [{
      plugin: require('../../packages/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"pageTemplateFolder":"src/templates","snekResourceId":"a58477a4-6e2a-4dca-80a9-e59d86bcac10"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
